.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blurOnHover {
  position: relative;
}
.blurOnHover img {
  width: 100%;
  vertical-align: top;
}
.blurOnHover:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.4s;
  z-index: 50;
  -webkit-transition: all 0.4s;
}
.blurOnHover:hover:after {
  opacity: 1;
}

/* Wizard  */

.wizardHeader {
  text-align: center;
}
.wizardHeader:before {
  content: "";
  width: 100%;
  top: 100%;
  height: 1px;
  background: #555;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#input-container {
  position: relative;
}

#input-container > img {
  position: absolute;
  top: 23px;
  left: 8px;
}

#input-container > input {
  padding-left: 40px;
}

.darkShadow:hover {
  box-shadow: 3px 4px 8px 2px #d5d4d4;
}
.cardShadow:hover {
  box-shadow: 3px 2px 20px 8px #f4a7ab;
}
.wizardShadow {
  box-shadow: 4px 7px 11px 3px #ac9c9c;
}

@media (min-width: 768px) {
  .firstStepIcon {
    margin-left: -0.5rem !important;
  }
}
@media (min-width: 1024px) {
  .firstStepIcon {
    margin-left: -2rem !important;
  }
}
@media (min-width: 1280px) {
  .firstStepIcon {
    margin-left: -4rem !important;
  }
}

@media (min-width: 1536px) {
  .firstStepIcon {
    margin-left: -5rem !important;
  }
}
@media (min-width: 2560px) {
  .firstStepIcon {
    margin-left: -9rem !important;
  }
}

input,
a,
button {
  outline-color: #de576a;
}
