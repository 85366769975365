.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: block;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  @apply transform scale-75 -translate-y-6;
}

input:focus-within ~ label {
  @apply text-gray-500;
}

.outline input:focus-within ~ label,
.outline input:not(:placeholder-shown) ~ label {
  @apply transform scale-75 -translate-y-4 z-0 ml-3 px-1 py-0;
}

.react-autosuggest__input {
  @apply w-full bg-primarybg text-center outline-none;
}

.react-autosuggest__suggestions-list {
  @apply text-black  absolute bg-white text-lg w-96 text-center bg-opacity-100 border-black border;
}


.tooltip {
  @apply invisible absolute;
  @apply p-1 rounded border border-gray-200 bg-gray-100 shadow-lg ml-4 text-sm
}

.has-tooltip:hover .tooltip {
  @apply visible z-50
}