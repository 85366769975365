.c-autosuggest .react-autosuggest__suggestions-list {
  width: 100%;
  border-style: solid;
  border-top-style: solid;
  border-width: 1px;
  border-color: #b0b0b045;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
  border-top-style: hidden;
  top: 2px;
}
.c-autosuggest .react-autosuggest__input {
  padding-left: 0.5rem;
  text-align: left;
}

.c-autosuggest .react-autosuggest__suggestions-container {
  position: relative;
}
